<template>
  <div v-if="isshowgrid">
    <va-card title="Genres List">
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input
            :value="term"
            placeholder="search"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
        <div class="flex xs12 md3 offset--md3">
          <va-button color="success" icon="fa fa-plus" @click="add()">Add</va-button>
        </div>
      </div>
      <va-data-table
        :fields="fields"
        :data="filteredData"
        :per-page="parseInt(perPage)"
        clickable
      >
        <template slot="actions" slot-scope="props">
          <va-button flat small color="gray" icon="fa fa-pencil" @click="edit(props.rowData)" class="ma-0"></va-button>
        </template>
      </va-data-table>
    </va-card>
  </div>
  <div v-else-if='isshowForm'>
    <div>
      <va-card :title="title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <va-select
              label="Organization*"
              placeholder="Select MSO Organization"
              v-model="type"
              textBy="id"
              :options="interfaceTypeOptions"
              :error="!!interfaceTypeErrors.length"
              :error-messages="interfaceTypeErrors"
              @input="checkInterfaceType()"
              noClear
            />
            <va-input
              v-model="priority"
              type="number"
              label="priority"
              placeholder="Enter Priority"
              :error="!!priorityErrors.length"
              :error-messages="priorityErrors"
            />

            <div class="d-flex justify--center mt-3">
              <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createGenres()">Create</va-button>
              <va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="updateGenres()">Update</va-button>
              <va-button type="submit" class="my-0" @click.prevent="list()">Cancel</va-button>
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
import { debounce } from 'lodash'
Vue.use(vueResource)
export default {
  name: 'genres',
  created () {
    this.getAllGenres();
    // const loader = Vue.$loading.show({ width: 40, height: 40 })
	   //  this.$http.get(config.menu.host + '/genres').then(response => {
	   //    loader.hide()
	   //    this.genresData = response.body
	   //    this.isshowForm = false
	   //    this.isshowgrid = true
	   //  }, error => {
	   //    loader.hide()
	   //    if (error && error.body) {
	   //      Vue.notify({ text: error.body, type: 'error' })
	   //    }
	   //  })
  },
  data () {
    return {
      term: null,
      perPage: '6',
      perPageOptions: ['4', '6', '10', '20'],
      isshowgrid: false,
      isshowForm: false,
      isCreate: false,
      isUpdate: false,
      showRemoveModal: false,
      genres: '',
      priority: '',
      title: '',
      genresData: [],
      genresErrors: [],
      priorityErrors: [],
    }
  },
  computed: {
    formReady () {
      return !this.genresErrors.length && !this.priorityErrors.length
    },
    fields () {
      return [{
        name: '__slot:marker',
        width: '30px',
        dataClass: 'text-center',
      },
      {
        name: 'genres',
        title: 'Genres',
      },
      {
        name: 'priority',
        title: 'Priority',
      },
      {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    filteredData () {
      return search(this.term, this.genresData)
    },
  },
  methods: {
	    getAllGenres () {
	      this.$http.get(config.menu.host + '/genres').then(response => {
	        this.genresData = (response.body.length > 0) ? response.body : []
          this.isshowForm = false
	        this.isshowgrid = true
	      })
	    },
    createGenres () {
      this.genresErrors = this.genres ? [] : ['Genres is required']
      this.priorityErrors = this.priority ? [] : ['Priority is required']
      if (!this.formReady) {
        return
      }
      var payload = {
        genres: this.genres,
        priority: this.priority,
      }
      this.$http.post(config.menu.host + '/genres', payload).then(responseData => {
        if (responseData && responseData.body) {
          Vue.notify({ text: responseData.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    updateGenres () {
      var payload = {
        genres: this.genres,
        priority: this.priority,
      }
      this.$http.put(config.menu.host + '/genres/' + this.genres_id, payload).then(responseData => {
        if (responseData && responseData.body) {
          Vue.notify({ text: responseData.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    add () {
	    this.title = 'Create MSO Setting'
      this.genres = ''
      this.priority = ''
      this.genresErrors = []
      this.priorityErrors = []
      this.isshowForm = true
      this.isshowgrid = false
      this.isUpdate = false
      this.isCreate = true
	  },
    edit (row) {
      this.title = 'Update Genres'
      this.genres_id = row.genres_id
      this.genres = row.genres
      this.priority = row.priority
      this.isshowgrid = false
      this.isshowForm = true
      this.isCreate = false
      this.isUpdate = true
    },
	    list () {
      this.isshowForm = false
      this.isshowgrid = true
      this.getAllGenres()
    },
	    search: debounce(function (term) {
	    	this.term = term
	    }, 400),
  },
}
</script>
